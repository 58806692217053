var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tasq && _vm.tasq.id)?_c('div',{attrs:{"id":_vm.tasq.id}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEpandedView),expression:"showEpandedView"}],staticClass:"bg-tasqNeutral900"},[(_vm.tasq && _vm.tasq.predictionType !== 'Producing')?_c('div',{staticClass:"tasq-item cursor-pointer overflow-hidden",class:(_vm.activeTasq || {}).id === (_vm.tasq || {}).id || _vm.tasqCompleted
          ? 'tasq-background-selected'
          : 'tasq-background-unselected',on:{"click":function($event){return _vm.goToTasqPage('id')}}},[_c('div',{staticClass:"grid gap-y-2"},[_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"float-left w-full mb-0 pb-0"},[_c('tasq-tag',{staticClass:"float-right",attrs:{"label":_vm.tasq.overriddenName}}),_c('p',{staticClass:"pb-0 my-0 font-medium text-white002 text-sm float-left overflow-hidden max-h-5",staticStyle:{"max-width":"calc(100% - 110px)"}},[_vm._v(" "+_vm._s(_vm.getPadName(_vm.tasq.wellName))+" ")]),_c('div',{staticClass:"font-light text-white clear-both float-left pt-0 mb-1 mt-3 opacity-70 font-light text-sm"},[_c('span',[_vm._v(" -"+_vm._s(_vm.tasq.padDeferment)+" "+_vm._s(_vm.getPadDefermentUnit)+" ")])]),(_vm.tasq.dateAgoText)?_c('div',{staticClass:"grid grid-flow-col gap-x-2 items-center truncated-text text-white float-right text-sm opacity-60 mt-2.5"},[_c('span',{staticClass:"material-icons opacity-90 text-base"},[_vm._v(" watch_later ")]),_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm.tasq.dateAgoText)+" ")])]):_vm._e()],1)]),_c('div',{staticClass:"grid items-center grid-flow-col justify-between"},[_c('div',{staticClass:"grid gap-x-1.5 grid-flow-col items-center"},[_c('div',{staticClass:"tasq-item__dot tasq-item__dot--first",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedFourDaysAgo,
              }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--second",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedThreeDaysAgo,
              }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--third",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedTwoDaysAgo,
              }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--fourth",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedOneDayAgo,
              }}),_c('div',{staticClass:"tasq-item__dot tasq-item__dot--fifth",class:{
                'tasq-item__dot--completed': _vm.tasq.respondedToday,
              }})]),_c('div',[_c('p',{staticClass:"w-24 text-xs float-right overflow-ellipsis overflow-hidden whitespace-nowrap text-white001 text-right"},[_vm._v(" "+_vm._s(_vm.fullName())+" ")])])]),_c('div',{staticClass:"pt-2.5 mt-4 border border-white008 h-8 w-full"},[_c('div',{staticClass:"w-full h-2 mt-2.5 rounded"},[_vm._l((_vm.predictionTypes),function(value,key,index){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                  theme: 'tasq-tooltip',
                  content: value + ' ' + key,
                  instantMove: true
                }),expression:"{\n                  theme: 'tasq-tooltip',\n                  content: value + ' ' + key,\n                  instantMove: true\n                }",modifiers:{"top":true}}],key:key,staticClass:"h-full float-left",class:[
                  _vm.getPredictionTypesColor(key),
                  index == 0 ? 'rounded-tl rounded-bl' : '',
                  _vm.predictionTypesLength - 1 == index ? 'rounded-tr rounded-br' : ''
                ],style:(`width: ${_vm.getPredictionTypesWidth(value)}%;`)})]})],2)])]),_c('div',{staticClass:"inline-block mt-2",on:{"click":function($event){$event.stopPropagation();return _vm.showWellsClicked.apply(null, arguments)}}},[_c('span',{staticClass:"material-icons text-2xl text-white opacity-70 float-left"},[_vm._v(" "+_vm._s(_vm.wellExpandedIcon())+" ")]),_c('p',{staticClass:"ml-1 float-left font-normal text-sm text-white005"},[_vm._v(" "+_vm._s(_vm.wellExpandedText())+" ")])])]):(_vm.tasq.predictionType === 'Producing')?_c('div',{staticClass:"tasq-item cursor-pointer overflow-hidden",class:(_vm.activeTasq || {}).id === (_vm.tasq || {}).id
          ? 'tasq-background-selected'
          : 'tasq-background-unselected',on:{"click":function($event){return _vm.goToTasqPage('producing')}}},[_c('div',{staticClass:"grid gap-y-2"},[_c('div',{staticClass:"pt-1 pb-1"},[_c('div',{staticClass:"float-left mb-0 pd-0 w-full h-14"},[_c('tasq-tag',{staticClass:"float-right",attrs:{"label":_vm.predictionTypeWell.predictionType}}),_c('p',{staticClass:"pb-0 my-0 font-medium text-white002 text-sm float-left overflow-ellipsis max-h-11 overflow-hidden",staticStyle:{"max-width":"calc(100% - 120px)","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(" "+_vm._s(_vm.getPadName(_vm.tasq.wellName))+" ")]),(_vm.padWells && _vm.padWells.length)?_c('div',{staticClass:"font-light clear-both float-left pt-0 my-1 opacity-70 font-light text-sm text-white"},[_c('span',[_vm._v(" -"+_vm._s(_vm.tasq.padDeferment)+" "+_vm._s(_vm.getPadDefermentUnit)+" ")])]):_vm._e()],1)]),_c('div',{staticClass:"w-full mt-1"},[_c('div',{staticClass:"w-full mt-0 rounded h-1.5"},[_vm._l((_vm.predictionTypes),function(value,key,index){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                  theme: 'tasq-tooltip',
                  content: value + ' ' + key,
                  instantMove: true
                }),expression:"{\n                  theme: 'tasq-tooltip',\n                  content: value + ' ' + key,\n                  instantMove: true\n                }",modifiers:{"top":true}}],key:key,staticClass:"h-full float-left",class:[
                  _vm.getPredictionTypesColor(key),
                  index == 0 ? 'rounded-tl rounded-bl' : '',
                  _vm.predictionTypesLength - 1 == index ? 'rounded-tr rounded-br' : ''
                ],style:(`width: ${_vm.getPredictionTypesWidth(value)}%;`)})]})],2)])]),_c('div',{staticClass:"inline-block mt-2",on:{"click":function($event){$event.stopPropagation();return _vm.showWellsClicked.apply(null, arguments)}}},[_c('span',{staticClass:"material-icons opacity-70 float-left text-2xl text-white"},[_vm._v(" "+_vm._s(_vm.wellExpandedIcon())+" ")]),_c('p',{staticClass:"ml-1 float-left font-normal text-sm text-white005"},[_vm._v(" "+_vm._s(_vm.wellExpandedText())+" ")])])]):_vm._e(),(_vm.wellListExpanded)?_c('div',{staticClass:"w-full"},[_c('div',[_c('p',{staticClass:"px-2 mt-4 uppercase text-white005 font-medium text-xs"},[_vm._v(" "+_vm._s(_vm.tasq.wells.length)+" Wells(s) ")])]),_c('div',{staticClass:"tasq-items"},_vm._l((_vm.getWells),function(tasq,i){return _c('tasq-item',{key:i,staticClass:"w-full",attrs:{"is-pad-level-page":true,"tasq-id":tasq.id,"view-level":'wellview',"tasq-completed":tasq.completed,"tasq-object":tasq}})}),1),_c('p',{staticClass:"px-2 pt-2 pb-4 uppercase cursor-pointer text-white005 text-sm font-medium",on:{"click":_vm.showWellsClicked}},[_vm._v(" Hide wells ")])]):_vm._e()]),_c('tasq-mini-view',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showEpandedView),expression:"!showEpandedView"}],attrs:{"tasq":_vm.tasq,"is-tasq-active":_vm.isTasqActive}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }